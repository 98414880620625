// ** Logo
import { updateTypes } from '@store/types'
import i18n from "i18next"
import {useEffect} from "react"
import {useDispatch} from "react-redux"
import useJwt from '@src/auth/jwt/useJwt'
import {updateOddsTypeByGeoLocalization} from "../../../redux/oddsType"
import {useSkin} from "../../../utility/hooks/useSkin"
import {
  isCheckGeoLocalization,
  isCheckTypes
} from "../../../utility/userData/userDataUtlis"

const SpinnerComponent = () => {
  const dispatch = useDispatch()
  const { skin } = useSkin()
  const illustration = skin === 'dark' ? 'Betfolio_logo_1.png' : 'Betfolio_logo_2.png',
    logo = require(`@src/assets/images/logo/${illustration}`)

  useEffect(() => {
    if (isCheckTypes()) {
      useJwt.getTypes(i18n.language).then(response => {
        const responseData = response.data.data
        dispatch(updateTypes(responseData))
      })
    }

    if (isCheckGeoLocalization()) {
      useJwt.getUserGeoLocalization().then((response) => {
        const responseData = response.data
        localStorage.setItem('geoLocalization', JSON.stringify(responseData))

        const countryCode = responseData.countryCode
        if (countryCode === 'US' || countryCode === 'CA') {
          dispatch(updateOddsTypeByGeoLocalization('moneyline'))
        } else if (countryCode === 'UK' || countryCode === 'IE' || countryCode === 'AU' || countryCode === 'NZ') {
          dispatch(updateOddsTypeByGeoLocalization('fractional'))
        }
      })
    }
  }, [])

  return (
    <div className='fallback-spinner app-loader'>
      <img className='fallback-logo' src={logo} width={170} alt='logo' />
      <div className='loading'>
        <div className='effect-1 effects'></div>
        <div className='effect-2 effects'></div>
        <div className='effect-3 effects'></div>
      </div>
    </div>
  )
}

export default SpinnerComponent
